body {
  font-family: "source-code-pro", monospace, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5 {
  /* font-family: Futura ND for Nike 365 Cn; */
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0px;
}

h1 {
  text-transform: uppercase;
  font-weight: normal;
  font-size: 24px;
  line-height: 60px;
  font-family: "bungee",sans-serif;
}

@media (min-width: 600px) {
  h1 {
    font-size: 38px;
    line-height: 80px;
  }
}

h3 {
  font-size: 22px;
  line-height: 26.4px;
}


h4 {
  font-size: 18px;
  line-height: 21.64px;
  text-transform: uppercase;
}

h5 {
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
}

p, li {
  font-size: 14px;
  line-height: 22.4px;
  font-weight: 400;
}

p.lighter, li.lighter {
  font-weight: 300;
}

a {
  color: inherit;
  text-decoration: underline dotted #999;
}

a:hover {
  text-decoration: underline;
}
